var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("UsersSmartGrid", {
    attrs: {
      users: _vm.users,
      maxWidth: _vm.maxWidth,
      minWidth: _vm.minWidth,
      showPinned: _vm.showPinned,
    },
    scopedSlots: _vm._u([
      {
        key: "option",
        fn: function (ref) {
          var user = ref.user
          return [
            _c("MeetingUserCard", {
              attrs: { user: user, dominantable: "", pinUI: _vm.pinUI },
              on: {
                onPin: function ($event) {
                  return _vm.onPin(user)
                },
                click: function ($event) {
                  return _vm.$emit("select", user.id)
                },
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }