<template>
  <UsersSmartGrid
    :users="users"
    :maxWidth="maxWidth"
    :minWidth="minWidth"
    :showPinned="showPinned"
  >
    <template #option="{ user }">
      <MeetingUserCard
        :user="user"
        dominantable
        :pinUI="pinUI"
        @onPin="onPin(user)"
        @click="$emit('select', user.id)"
      />
    </template>
  </UsersSmartGrid>
</template>

<script>
import Vue from "vue"
import { mapGetters } from "vuex"
import { db } from "@/firebase"
import _ from "lodash"
import fit from "@shared/helpers/fit"
import User from "@shared/User"
import MeetingUserCard from "@/components/GroupTeams/Common/Player/MeetingUserCard/MeetingUserCard"
import UsersSmartGrid from "./UsersSmartGrid"

import {
  NotificationMixin,
  NotificationType,
  NotificationScope
} from "@/mixins/NotificationMixin"

const MIN_WIDTH = 160
const MAX_WIDTH = 600

export default Vue.extend({
  name: "MeetingSmartGrid",
  components: {
    MeetingUserCard,
    UsersSmartGrid
  },
  mixins: [NotificationMixin],
  props: {
    maxWidth: {
      type: Number,
      default: MAX_WIDTH,
      required: false
    },
    minWidth: {
      type: Number,
      default: MIN_WIDTH,
      required: false
    },
    users: {
      type: Array,
      required: true
    },
    pinUI: {
      type: Boolean,
      default: false
    },
    showPinned: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      width: 0,
      height: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.height = this.$refs.grid?.offsetHeight || 0
      this.width = this.$refs.grid?.offsetWidth || 0
    })
  },
  computed: {
    ...mapGetters("auth", ["isHost"]),
    ...mapGetters(["isDIYGame", "actualGameID", "orgID"]),

    pinedUserID() {
      return (
        this.pinUI &&
        this.showPinned &&
        this.$store.getters?.gameStatus?.pinedUserID
      )
    }
  },
  methods: {
    onPin(user) {
      this.togglePin(user.id)
      this.notifyPin(user)
    },
    togglePin(userID) {
      const path = `org/${this.orgID}/game/${this.actualGameID}/gameStatus/pinedUserID`
      db.auxiliary()
        .ref(path)
        .set(this.pinedUserID !== userID && userID)
    },
    notifyPin(user) {
      if (this.isHost || this.isDIYGame) {
        this.$_NotificationMixin_send({
          notificationBy: this.player,
          addText: User.getShortenedName(user),
          type: this.pinedUserID
            ? NotificationType.PINNED_BY_USER
            : NotificationType.UNPINNED_BY_USER,
          scope: NotificationScope.GLOBAL
        })
      }
    }
  }
})
</script>
